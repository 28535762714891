import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { CycleService, PartnerService, ProductService } from '../generated';
import { RegionService } from '../generated/api/region.service';
import { RootActions } from './root.actions';

@Injectable()
export class RootEffects {
  private actions$: Actions = inject(Actions);

  loadPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.LoadPartners),
      exhaustMap(() =>
        this.partnerService.getDistributorPartners().pipe(
          map((data) => RootActions.LoadPartnersSuccess({ data })),
          catchError((error) => [RootActions.LoadPartnersFailed({ error })]),
        ),
      ),
    ),
  );

  loadRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.LoadRegions),
      exhaustMap(() =>
        this.regionService.getRegions().pipe(
          map((data) => RootActions.LoadRegionsSuccess({ data })),
          catchError((error) => [RootActions.LoadRegionsFailed({ error })]),
        ),
      ),
    ),
  );

  loadProductLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.LoadProductLines),
      exhaustMap(() =>
        this.productService.getProductLines().pipe(
          map((data) => RootActions.LoadProductLinesSuccess({ data })),
          catchError((error) => [
            RootActions.LoadProductLinesFailed({ error }),
          ]),
        ),
      ),
    ),
  );

  getCurrentCycle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.GetCurrentCycle),
      exhaustMap(() =>
        this.cycleService.getCurrentCycle().pipe(
          map((data) => RootActions.GetCurrentCycleSuccess({ data })),
          catchError((error) => [RootActions.GetCurrentCycleFailed({ error })]),
        ),
      ),
    ),
  );

  goToNextCycle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.GoToNextCycle),
      exhaustMap(() =>
        this.cycleService.goToNextCycle().pipe(
          map((data) => RootActions.GoToNextCycleSuccess({ data })),
          catchError((error) => [RootActions.GoToNextCycleFailed({ error })]),
        ),
      ),
    ),
  );

  constructor(
    private cycleService: CycleService,
    private partnerService: PartnerService,
    private regionService: RegionService,
    private productService: ProductService,
  ) {}
}
