// used to make sure all environments have same properties
export class EnvironmentBase {
  static palfinger_api_management = '';
  static palfinger_api_management_subs_key = '';
  static palfinger_app_gateway = '';
  static oidc_clientid = '';
  static oidc_scope = '';

  static LINKS: {
    STATUS_PALDESK_PAGE: any;
    SECURITY_TOKEN_SERVICE: string;
    POST_LOGOUT_REDIRECT_URI: string;
    PALDESK_BASEPATH: string;
    APP_HELP_PATH: string;
    APP_NEWS_PATH: string;
  } = {
    STATUS_PALDESK_PAGE: '',
    SECURITY_TOKEN_SERVICE: '',
    POST_LOGOUT_REDIRECT_URI: '',
    PALDESK_BASEPATH: '',
    APP_NEWS_PATH: '',
    APP_HELP_PATH: '',
  };

  static roles: string[] = [];

  static domainsForApiManagementSubsKey: string[];

  static identityServerAuthConf: {
    whitelistedDomains: string[];
    whitelistedRoutes: string[];
  } = {
    whitelistedDomains: [],
    whitelistedRoutes: [],
  };
  static FEATUREFLAGS: {
    USE_CSI_SURVEY: string;
  } = {
    USE_CSI_SURVEY: '',
  };
}
