import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, mergeMap } from 'rxjs/operators';

import { UserService } from '../generated';
import { SelectUserDialogActions } from './select-user-dialog.actions';

@Injectable()
export class SelectUserDialogEffects {
  private actions$: Actions = inject(Actions);

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelectUserDialogActions.LoadUsers),
      mergeMap(({ partnerId }) =>
        this.userService.getUsersByPartnerId(partnerId).pipe(
          map((data) =>
            SelectUserDialogActions.LoadUsersSuccess({ partnerId, data }),
          ),
          catchError((error) => [
            SelectUserDialogActions.LoadUsersFailed({ partnerId, error }),
          ]),
        ),
      ),
    ),
  );

  constructor(private userService: UserService) {}
}
