import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Data,
  Params,
  RouterStateSnapshot,
} from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

const dataBlacklist = ['sidebar', 'breadcrumb'];

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  data: Data;
}

@Injectable()
/**
 * Adjusted apps\paldesk\src\app\shared\ngrx\custom.router.state.serializer.ts to remove the
 * unserializable "sidebar" property in the route data
 */
export class TrendMonitoringRouterStateSerializer
  implements RouterStateSerializer<RouterStateUrl>
{
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    return {
      url: routerState.url,
      params: mergeRouteParams(routerState.root, (r) => r.params),
      queryParams: mergeRouteParams(routerState.root, (r) => r.queryParams),
      data: mergeRouteData(routerState.root),
    };
  }
}

function getPrimaryChild(
  route: ActivatedRouteSnapshot | null,
): ActivatedRouteSnapshot | null {
  if (!route) {
    return null;
  }
  return route.children.find((c) => c.outlet === 'primary') || route.firstChild;
}

function mergeRouteParams(
  route: ActivatedRouteSnapshot | null,
  getter: (r: ActivatedRouteSnapshot) => Params,
): Params {
  if (!route) {
    return {};
  }
  const currentParams = getter(route);
  const primaryChild = getPrimaryChild(route);
  return {
    ...currentParams,
    ...mergeRouteParams(primaryChild, getter),
  };
}

function mergeRouteData(route: ActivatedRouteSnapshot | null): Data {
  if (!route) {
    return {};
  }
  const currentData = { ...route.data };

  for (const property of dataBlacklist) {
    if (property in currentData) {
      delete currentData[property];
    }
  }

  const primaryChild = getPrimaryChild(route);
  return { ...currentData, ...mergeRouteData(primaryChild) };
}
