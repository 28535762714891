<ds-app-wrapper [menu]="menu">
  <ds-breadcrumbs
    fxFlex="0 0 auto"
    [baseUrl]="'/'"
    baseLabel="trend_monitoring.default"
  >
  </ds-breadcrumbs>
  <div fxFlex="1 1 auto" class="flex-page-container">
    <router-outlet></router-outlet>
  </div>
</ds-app-wrapper>
