import { Dictionary } from '@ngrx/entity';
import { Action, createReducer } from '@ngrx/store';
import { produceOn } from '@shared-lib/rxjs';
import { UserDto } from '../generated';
import { BackendStoreState } from '../model/backend-store-state.model';
import { SelectUserDialogActions } from './select-user-dialog.actions';

export const featureName = 'selectUserDialog';

export interface State {
  users: Dictionary<BackendStoreState<UserDto[]>>;
}

export const initialState: State = {
  users: {},
};

const selectUserDialogReducer = createReducer(
  initialState,

  produceOn(SelectUserDialogActions.LoadUsers, (draft, { partnerId }) => {
    draft.users[partnerId] = {
      data: draft.users[partnerId] && draft.users[partnerId]?.data,
      loading: true,
      error: undefined,
    };
  }),

  produceOn(
    SelectUserDialogActions.LoadUsersSuccess,
    (draft, { partnerId, data }) => {
      draft.users[partnerId] = {
        data,
        loading: false,
      };
    },
  ),

  produceOn(
    SelectUserDialogActions.LoadUsersFailed,
    (draft, { partnerId, error }) => {
      draft.users[partnerId] = {
        loading: false,
        error,
      };
    },
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return selectUserDialogReducer(state, action);
}
