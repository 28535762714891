import { Component } from '@angular/core';
import { APPS } from '@config';
import { Menu } from '@design-system/feature/app-wrapper-v2';
import { UserService } from '@features/auth';
import { filter, take } from 'rxjs';

@Component({
  selector: 'tm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  menu: Menu = {
    title: 'trend_monitoring.default',
    titleIcon: 'equalizer',
    navigation: [
      {
        title: 'trend_monitoring.navigation.distributors',
        icon: 'assignment',
        routerLink: '/distributorList',
      },
    ],
  };

  constructor(private userService: UserService) {
    this.userService.isAuthorized$
      .pipe(
        filter((r) => !!r),
        take(1),
      )
      .subscribe({
        next: () => {
          if (this.userService.hasRole(APPS.TREND_MONITORING.ROLES.ADMIN)) {
            this.menu.navigation.push(
              {
                title: 'trend_monitoring.navigation.configure_regions',
                icon: 'assignment',
                routerLink: '/admin/regions',
              },
              {
                title: 'trend_monitoring.navigation.configure_product_clusters',
                icon: 'assignment',
                routerLink: '/admin/productClusters',
              },
              {
                title: 'trend_monitoring.navigation.configure_products',
                icon: 'assignment',
                routerLink: '/admin/products',
              },
            );
          }
          if (
            this.userService.hasOneRole([
              APPS.TREND_MONITORING.ROLES.ADMIN,
              APPS.TREND_MONITORING.ROLES.REGION_ADMIN,
            ])
          ) {
            this.menu.navigation.push(
              {
                title: 'trend_monitoring.navigation.configure_sales_directors',
                icon: 'assignment',
                routerLink: '/admin/salesDirectors',
              },
              {
                title:
                  'trend_monitoring.navigation.configure_area_sales_managers',
                icon: 'assignment',
                routerLink: '/admin/areaSalesManagers',
              },
            );
          }
        },
      });
  }
}
