/**
 * Palfinger TrendMonitoring API
 * API Gateway for TrendMonitoring
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum PlannedDemandStatus {
    open = 'open',
    submitted = 'submitted',
    reopened = 'reopened'
};

