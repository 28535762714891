import { createReducer } from '@ngrx/store';
import { produceOn } from '@shared-lib/rxjs';
import {
  Cycle,
  DistributorPartnerDto,
  PlannedDemandStatus,
  ProductLineDto,
  RegionDto,
} from '../generated';
import { BackendStoreState } from '../model/backend-store-state.model';
import { RootActions } from './root.actions';

export const featureName = 'root';

export interface State {
  partners?: BackendStoreState<DistributorPartnerDto[]>;
  regions?: BackendStoreState<RegionDto[]>;
  productLines?: BackendStoreState<ProductLineDto[]>;
  nextCycleState?: BackendStoreState<void>;
  currentCycle?: BackendStoreState<Cycle>;
}

export const initialState: State = {};

const {
  LoadPartners,
  LoadPartnersSuccess,
  LoadPartnersFailed,
  InvalidatePartners,
  LoadRegions,
  LoadRegionsFailed,
  LoadRegionsSuccess,
  LoadProductLines,
  LoadProductLinesSuccess,
  LoadProductLinesFailed,
  GetCurrentCycle,
  GetCurrentCycleSuccess,
  GetCurrentCycleFailed,
  GoToNextCycle,
  GoToNextCycleSuccess,
  GoToNextCycleFailed,
} = RootActions;

export const reducer = createReducer(
  initialState,

  produceOn(LoadPartners, (draft) => {
    draft.partners = { loading: true };
  }),
  produceOn(LoadPartnersSuccess, (draft, { data }) => {
    draft.partners = { data };
  }),
  produceOn(LoadPartnersFailed, (draft, { error }) => {
    draft.partners = { error };
  }),
  produceOn(InvalidatePartners, (draft) => {
    draft.partners = undefined;
  }),

  produceOn(LoadRegions, (draft) => {
    draft.regions = { loading: true };
  }),
  produceOn(LoadRegionsSuccess, (draft, { data }) => {
    draft.regions = { data };
  }),
  produceOn(LoadRegionsFailed, (draft, { error }) => {
    draft.regions = { error };
  }),

  produceOn(LoadProductLines, (draft) => {
    draft.productLines = { loading: true };
  }),
  produceOn(LoadProductLinesSuccess, (draft, { data }) => {
    draft.productLines = { data };
  }),
  produceOn(LoadProductLinesFailed, (draft, { error }) => {
    draft.productLines = { error };
  }),

  produceOn(GetCurrentCycle, (draft) => {
    draft.currentCycle = { loading: true };
  }),
  produceOn(GetCurrentCycleSuccess, (draft, { data }) => {
    draft.currentCycle = { data };
  }),
  produceOn(GetCurrentCycleFailed, (draft, { error }) => {
    draft.currentCycle = { error };
  }),

  produceOn(GoToNextCycle, (draft) => {
    draft.nextCycleState = { loading: true };
  }),
  produceOn(GoToNextCycleSuccess, (draft, { data }) => {
    draft.nextCycleState = {};
    draft.currentCycle = { data };
    if (draft.partners?.data?.length) {
      for (const item of draft.partners.data) {
        item.status = PlannedDemandStatus.open;
      }
    }
  }),
  produceOn(GoToNextCycleFailed, (draft, { error }) => {
    draft.nextCycleState = { error };
  }),
);
