import { createAction, props, union } from '@ngrx/store';
import { UserDto } from '../generated';

export namespace SelectUserDialogActions {
  export const LoadUsers = createAction(
    '[Select User Dialog Component] Load users for partner',
    props<{ partnerId: number }>(),
  );
  export const LoadUsersSuccess = createAction(
    '[Select User Dialog Component] Load users for partner successfull',
    props<{ partnerId: number; data: UserDto[] }>(),
  );
  export const LoadUsersFailed = createAction(
    '[Select User Dialog Component] Load users for partner failed',
    props<{ partnerId: number; error: any }>(),
  );

  const actions = union({
    LoadUsers,
  });

  export type Types = typeof actions;
}
