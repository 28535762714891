import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes, ActivatedRouteSnapshot } from '@angular/router';
import { AuthGuard, AuthPermissions } from '@features/auth';

const routes: Routes = [
  {
    path: '',
    data: {
      permissions: {
        roles: [
          'APP204:trendmonitoring_admin',
          'APP204:trendmonitoring_write',
          'APP204:trendmonitoring_read',
        ],
      } as AuthPermissions,
    },
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    canActivateChild: [
      (next: ActivatedRouteSnapshot) =>
        inject(AuthGuard).canActivateChild(next),
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
