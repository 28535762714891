import { createAction, props, union } from '@ngrx/store';

import {
  Cycle,
  DistributorPartnerDto,
  ProductLineDto,
  RegionDto,
} from '../generated';

export namespace RootActions {
  export const LoadPartners = createAction(
    '[Distributor List Component] Load partners',
  );
  export const LoadPartnersSuccess = createAction(
    '[Distributor List Component] Load partners successfull',
    props<{ data: DistributorPartnerDto[] }>(),
  );
  export const LoadPartnersFailed = createAction(
    '[Distributor List Component] Load partners failed',
    props<{ error: any }>(),
  );

  export const InvalidatePartners = createAction(
    '[TrendMonitoringInputComponent] Invalidate partners',
  );

  export const LoadRegions = createAction('[Root Store] Load regions');
  export const LoadRegionsSuccess = createAction(
    '[Root Effects] Load regions successful',
    props<{ data: RegionDto[] }>(),
  );
  export const LoadRegionsFailed = createAction(
    '[Root Effects] Load regions failed',
    props<{ error: any }>(),
  );

  export const LoadProductLines = createAction(
    '[Root Store] Load product lines',
  );
  export const LoadProductLinesSuccess = createAction(
    '[Root Effects] Load product lines successful',
    props<{ data: ProductLineDto[] }>(),
  );
  export const LoadProductLinesFailed = createAction(
    '[Root Effects] Load product lines failed',
    props<{ error: any }>(),
  );

  export const GetCurrentCycle = createAction('[Root] Get current cycle');
  export const GetCurrentCycleSuccess = createAction(
    '[Root] Get current cycle success',
    props<{ data: Cycle }>(),
  );
  export const GetCurrentCycleFailed = createAction(
    '[Root] Get current cycle failed',
    props<{ error: any }>(),
  );

  export const GoToNextCycle = createAction(
    '[Distributor List] Go to next cycle',
  );
  export const GoToNextCycleSuccess = createAction(
    '[Root Effects] Go to next cycle success',
    props<{ data: Cycle }>(),
  );
  export const GoToNextCycleFailed = createAction(
    '[Root Effects] Go to next cycle failed',
    props<{ error: any }>(),
  );

  const actions = union({
    LoadPartners,
    LoadPartnersSuccess,
    LoadPartnersFailed,
    InvalidatePartners,
    LoadRegions,
    LoadRegionsSuccess,
    LoadRegionsFailed,
    LoadProductLines,
    LoadProductLinesSuccess,
    LoadProductLinesFailed,
    GetCurrentCycle,
    GetCurrentCycleSuccess,
    GetCurrentCycleFailed,
    GoToNextCycle,
    GoToNextCycleSuccess,
    GoToNextCycleFailed,
  });

  export type Types = typeof actions;
}
